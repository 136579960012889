import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
// @mui
import { Box, Button, Typography, Container } from '@mui/material';
// components
import Page from '../components/Page';
import { SeverErrorIllustration } from '../assets';
import MaintenanceIllustration from '../assets/illustration_maintenance';
import useLocales from '../hooks/useLocales';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function ErrorPage() {
  const { translate } = useLocales();

  return (
    <Page title={translate('GLOBAL.ERROR')} sx={{ height: 1 }}>
      <RootStyle>
        <Container>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <Typography variant="h3" paragraph>
              {translate('GLOBAL.SOMETHING_WENT_WRONG')}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              {translate('GLOBAL.CONTACT_SUPPORT')}
            </Typography>

            <MaintenanceIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
