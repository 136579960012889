import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
//
import enLocales from './en.json';
import enLocalesForUAE from './enUAE.json';
import ruLocales from './ru.json';
import ruLocalesForUAE from './ruUAE.json';
import kzLocales from './kz.json';
import { API, HOST_API } from '../constants/constants';

// ----------------------------------------------------------------------

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: API === HOST_API.UAE ? enLocalesForUAE : enLocales },
      ru: { translations: API === HOST_API.UAE ? ruLocalesForUAE : ruLocales },
      kz: { translations: kzLocales },
    },
    lng: localStorage.getItem('i18nextLng') || 'ru',
    fallbackLng: 'en',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
