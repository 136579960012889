import React, { Component, ErrorInfo, ReactNode } from "react";
import NoAccess from '../pages/NoAccess';
import ErrorPage from '../pages/ErrorPage';
import { dispatch } from '../redux/store';
import { logout } from '../redux/slices/user';
import { PATH_AUTH } from '../routes/paths';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public handleLogout = async () => {
    try {
      await dispatch(logout());
    } catch (error) {
      console.error(error);
    }
  };

  public render() {
    if (this.state.hasError) {
      this.handleLogout().catch()

      return <ErrorPage/>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
