export enum LANGS {
  EN = 'en',
  RU = 'ru',
  UA = 'ua',
}

export enum THEMES {
  LIGHT = 'LIGHT',
  ONE_DARK = 'ONE_DARK',
  UNICORN = 'UNICORN',
}

export enum FIELD_TYPES {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  SELECT = 'SELECT',
  DATE = 'DATE',
  CERTIFICATE_ID = 'CERTIFICATE_ID',
  BUTTON = 'BUTTON'
}

export enum ROLE_TYPES {
  ADMIN = 'Admin',
  LOCAL_ADMIN = 'LocalAdmin'
}

export enum TYPE_DATES {
  SaleDate = 0,
  QueryFillDate = 1,
  ApprovedDate = 2,
  RejectionDate = 3,
  ClarifyDate = 4,
}

export enum SaleTypeEnum {
  None,
  PayOffice,
  KeepTalk
}

export enum DestinationType {
  None,
  Android,
  IOS
}

export enum Period {
  Today = 'Today',
  Yesterday = 'Yesterday',
  CurrentWeek = 'CurrentWeek',
  LastWeek = 'LastWeek',
  CurrentMonth = 'CurrentMonth',
  LastMonth = 'LastMonth',
  CurrentQuarter = 'CurrentQuarter',
  LastQuarter = 'LastQuarter',
  CurrentYear = 'CurrentYear',
  LastYear = 'LastYear',
  Manual = 'Manual',
}

export enum SourceTypeEnum {
  Office = 'Office',
  KTApp = 'KTApp',
  TLBot = 'TLBot',
  OfficeForKTApp = 'OfficeForKTApp',
  MechtaSite = 'MechtaSite',
  None = 'None'
}

export enum AdditionalApprovalStatus {
  None = 'None',
  // eslint-disable-next-line camelcase
  Pre_Approved = 'Pre_Approved', // Предодобренная
  // eslint-disable-next-line camelcase
  Pre_Approval = 'Pre_Approval', // Предапрув
  Renouncement = 'Renouncement', // Отказ
  Approved = 'Approved',      // Одобренная
  ManagerWait = 'ManagerWait',
  UserDidntChangeCertificate = 'UserDidntChangeCertificate'
}

