// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';
import { PAGE_NAMES } from '../../../constants/constants';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name: string) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  print: getIcon('ic_print'),
  exchange: getIcon('ic_exchange'),
  registerLoading: getIcon('ic_register-loading'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  device: getIcon('ic_device'),
  plus: <Iconify icon={'eva:plus-fill'} />,
  gift: <Iconify icon={'ph:gift'} />,
};

const navConfig = [
  {
    subheader: 'NAVIGATION.MANAGEMENT',
    items: [
      {
        title: 'STATISTIC.STATISTIC',
        path: PATH_DASHBOARD.general.stats,
        icon: ICONS.dashboard,
        claims: [PAGE_NAMES.STATISTIC_VIEW],
      },
      // { title: 'Печать', path: PATH_DASHBOARD.general.print, icon: ICONS.print },
      {
        title: 'SALES.NEW_SALE',
        path: PATH_DASHBOARD.sales.create,
        icon: ICONS.plus,
        claims: [PAGE_NAMES.SALES_CREATE],
      },
      {
        title: 'EXCHANGE.NEW',
        path: PATH_DASHBOARD.exchange.create,
        icon: ICONS.plus,
        claims: [PAGE_NAMES.EXCHANGE_CREATE],
      },
      {
        title: 'SALES.LIST_TITLE',
        path: PATH_DASHBOARD.general.sales,
        icon: ICONS.ecommerce,
        claims: [PAGE_NAMES.SALES_LIST],
      },
      {
        title: 'EXCHANGE.LIST_TITLE',
        path: PATH_DASHBOARD.general.exchange,
        icon: ICONS.exchange,
        claims: [PAGE_NAMES.EXCHANGE_LIST],
      },
      {
        title: 'NAVIGATION.REGISTER_LOADING',
        path: PATH_DASHBOARD.general.registerLoading,
        icon: ICONS.registerLoading,
        claims: [PAGE_NAMES.REGISTRIES_VIEW],
      },
      {
        title: 'NAVIGATION.REPORTS',
        path: PATH_DASHBOARD.general.report,
        icon: ICONS.registerLoading,
        claims: [PAGE_NAMES.REPORT_LIST],
      },
    ],
  },
  {
    subheader: 'NAVIGATION.ADMINISTRATION',
    claims: [
      PAGE_NAMES.USER_LIST,
      PAGE_NAMES.ROLE_LIST,
      PAGE_NAMES.SHOP_BRANCH_LIST,
      PAGE_NAMES.SHOP_LIST,
      PAGE_NAMES.COUNTRY_LIST,
      PAGE_NAMES.CITY_LIST,
      PAGE_NAMES.DEVICE_LIST,
      PAGE_NAMES.DEVICE_BRAND_LIST,
      PAGE_NAMES.CERTIFICATE_SERIES_LIST,
      PAGE_NAMES.CERTIFICATE_EMISSION_LIST,
      PAGE_NAMES.CERTIFICATE_LIST,
      PAGE_NAMES.PRICE_PERCENT_LIST,
      PAGE_NAMES.CATEGORY_LIST,
      PAGE_NAMES.COMPENSATION_PERCENT_LIST,
    ],
    items: [
      {
        title: 'USERS.TITLE',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        claims: [PAGE_NAMES.USER_LIST, PAGE_NAMES.ROLE_LIST],
        children: [
          { title: 'PARTNER.TITLE', path: PATH_DASHBOARD.user.partnerList },
          { title: 'USERS.TITLE', path: PATH_DASHBOARD.user.list, claims: [PAGE_NAMES.USER_LIST] },
          { title: 'ROLES.TITLE', path: PATH_DASHBOARD.user.roles.list, claims: [PAGE_NAMES.ROLE_LIST] },
          { title: 'CUSTOMER_CONNECTION.TITLE', path: PATH_DASHBOARD.user.customerConnection },
          { title: 'PACKAGE.TITLE', path: PATH_DASHBOARD.user.packageList },
          { title: 'PAYMENT.TITLE', path: PATH_DASHBOARD.user.payment },
        ],
      },

      {
        title: 'SHOP_BRANCHES.TITLE',
        path: PATH_DASHBOARD.shops.root,
        icon: ICONS.cart,
        claims: [PAGE_NAMES.SHOP_BRANCH_LIST, PAGE_NAMES.SHOP_LIST, PAGE_NAMES.COUNTRY_LIST, PAGE_NAMES.CITY_LIST],
        children: [
          { title: 'SHOP_BRANCHES.TITLE', path: PATH_DASHBOARD.shopBranch.list, claims: [PAGE_NAMES.SHOP_BRANCH_LIST] },
          { title: 'SHOP.TITLE', path: PATH_DASHBOARD.shops.list, claims: [PAGE_NAMES.SHOP_LIST] },
          { title: 'COUNTRY.TITLE', path: PATH_DASHBOARD.country.list, claims: [PAGE_NAMES.COUNTRY_LIST] },
          { title: 'CITY.TITLE', path: PATH_DASHBOARD.city.list, claims: [PAGE_NAMES.CITY_LIST] },
        ],
      },

      {
        title: 'DEVICES.TITLE',
        path: PATH_DASHBOARD.devices.root,
        icon: ICONS.device,
        claims: [PAGE_NAMES.DEVICE_LIST, PAGE_NAMES.DEVICE_BRAND_LIST],
        children: [
          { title: 'DEVICES.TITLE', path: PATH_DASHBOARD.devices.list, claims: [PAGE_NAMES.DEVICE_LIST] },
          {
            title: 'DEVICES.BRANDS.TITLE',
            path: PATH_DASHBOARD.devices.brand.list,
            claims: [PAGE_NAMES.DEVICE_BRAND_LIST],
          },
        ],
      },

      // MANAGEMENT : CERTIFICATES
      {
        title: 'GLOBAL.CERTIFICATES',
        path: PATH_DASHBOARD.certificates.root,
        icon: ICONS.blog,
        claims: [PAGE_NAMES.CERTIFICATE_SERIES_LIST, PAGE_NAMES.CERTIFICATE_EMISSION_LIST, PAGE_NAMES.CERTIFICATE_LIST],
        children: [
          {
            title: 'CERTIFICATE_SERIES.TITLE',
            path: PATH_DASHBOARD.certificates.series.list,
            claims: [PAGE_NAMES.CERTIFICATE_SERIES_LIST],
          },
          {
            title: 'ROLES.EMISSION',
            path: PATH_DASHBOARD.blog.postById,
            claims: [PAGE_NAMES.CERTIFICATE_EMISSION_LIST],
          },
          { title: 'ROLES.CERT_CARDS', path: PATH_DASHBOARD.blog.newPost, claims: [PAGE_NAMES.CERTIFICATE_LIST] },
        ],
      },

      // MANAGEMENT : BONUSES
      {
        title: 'BONUSES.TITLE',
        path: PATH_DASHBOARD.bonus.root,
        icon: ICONS.gift,
        claims: [
          PAGE_NAMES.BONUSES_LIST,
          PAGE_NAMES.CASHBACK_LIST,
          PAGE_NAMES.PROMO_CODE_LIST,
          PAGE_NAMES.PROMOTION_LIST,
          PAGE_NAMES.BONUSES_EDIT,
        ],
        children: [
          { title: 'BONUSES.SUBTITLE', path: PATH_DASHBOARD.bonus.list, claims: [PAGE_NAMES.BONUSES_LIST] },
          {
            title: 'PROMO_CODE.TITLE',
            path: PATH_DASHBOARD.bonus.promoCode.list,
            claims: [PAGE_NAMES.PROMO_CODE_LIST],
          },
          { title: 'CASHBACK.TITLE', path: PATH_DASHBOARD.bonus.cashback.list, claims: [PAGE_NAMES.CASHBACK_LIST] },
          { title: 'PROMOTION.TITLE', path: PATH_DASHBOARD.bonus.promotion.list, claims: [PAGE_NAMES.PROMOTION_LIST] },
          {
            title: 'BONUSES.MANUAL_MANAGEMENT',
            path: PATH_DASHBOARD.bonus.manualManagement,
            claims: [PAGE_NAMES.BONUSES_EDIT],
          },
        ],
      },

      // MANAGEMENT : OTHER
      {
        title: 'GLOBAL.OTHER',
        path: PATH_DASHBOARD.other.root,
        icon: ICONS.blog,
        claims: [PAGE_NAMES.PRICE_PERCENT_LIST, PAGE_NAMES.CATEGORY_LIST, PAGE_NAMES.COMPENSATION_PERCENT_LIST],
        children: [
          {
            title: 'PERCENTAGE_PRICE.TITLE',
            path: PATH_DASHBOARD.other.percentagePrice.list,
            claims: [PAGE_NAMES.PRICE_PERCENT_LIST],
          },
          { title: 'CATEGORY.TITLE', path: PATH_DASHBOARD.other.category.list, claims: [PAGE_NAMES.CATEGORY_LIST] },
          {
            title: 'CATEGORY_TYPE.TITLE',
            path: PATH_DASHBOARD.other.categoryType.list,
            claims: [PAGE_NAMES.CATEGORY_LIST],
          },
          {
            title: 'PERCENTAGE_COMPENSATION.TITLE',
            path: PATH_DASHBOARD.other.percentageCompensation.list,
            claims: [PAGE_NAMES.COMPENSATION_PERCENT_LIST],
          },
        ],
      },
    ],
  },
];

const navConfig2 = [
  {
    subheader: 'Управление',
    items: [
      { title: 'Статистика', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },
  {
    subheader: 'Администрирование',
    items: [
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'profile', path: PATH_DASHBOARD.user.profile },
          { title: 'cards', path: PATH_DASHBOARD.user.cards },
          { title: 'list', path: PATH_DASHBOARD.user.list },
          { title: 'create', path: PATH_DASHBOARD.user.newUser },
          { title: 'edit-create', path: PATH_DASHBOARD.user.editById },
          { title: 'account', path: PATH_DASHBOARD.user.account },
        ],
      },

      // MANAGEMENT : E-COMMERCE
      {
        title: 'e-commerce',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.cart,
        children: [
          { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
          { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
          { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
          { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
          { title: 'edit-create', path: PATH_DASHBOARD.eCommerce.editById },
          { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
          { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
        ],
      },

      // MANAGEMENT : BLOG
      {
        title: 'blog',
        path: PATH_DASHBOARD.blog.root,
        icon: ICONS.blog,
        children: [
          { title: 'posts', path: PATH_DASHBOARD.blog.posts },
          { title: 'post', path: PATH_DASHBOARD.blog.postById },
          { title: 'new post', path: PATH_DASHBOARD.blog.newPost },
        ],
      },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      {
        title: 'mail',
        path: PATH_DASHBOARD.mail.root,
        icon: ICONS.mail,
        info: (
          <Label variant="outlined" color="error">
            +32
          </Label>
        ),
      },
      { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
      { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      {
        title: 'kanban',
        path: PATH_DASHBOARD.kanban,
        icon: ICONS.kanban,
      },
    ],
  },
];

export default navConfig;
