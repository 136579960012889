import { Button, DialogActions, DialogContent, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { DialogAnimate, varFade } from './animate';
import { dispatch } from '../redux/store';
import { closeWelcomeModal } from '../redux/slices/user';
import useLocales from '../hooks/useLocales';

// ----------------------------------------------------------------------

export default function WelcomeModal() {
  const { showWelcomeModal } = useSelector((state: any) => state.user);
  const { translate } = useLocales();

  const handleClose = () => {
    dispatch(closeWelcomeModal());
  };

  return (
    <DialogAnimate open={showWelcomeModal} onClose={handleClose} variants={varFade().inUp} sx={{}}>

      <DialogContent sx={{paddingBottom: 1}}>
        <Typography variant='subtitle1' sx={{ flexGrow: 1 }}>
          {translate('GLOBAL.WELCOME_FREEDOM')}
        </Typography>
      </DialogContent>

      <DialogActions sx={{ py: 1, px: 3, display: 'flex', justifyContent: 'center' }}>
        <Button variant={'contained'} onClick={handleClose}>{'OK'}</Button>
      </DialogActions>
    </DialogAnimate>
  );
}
