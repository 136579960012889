import axios from 'axios';
// config
import { HOST_API } from '../config';
import { triggerLoader } from '../redux/slices/utils';
import { logout, tryToReLogin } from '../redux/slices/user';
import { dispatch } from '../redux/store';

axios.defaults.headers.common['X-Access-Token'] = String(localStorage.getItem('accessToken'));

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

export const uninterceptedAxiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.response.use(
  async (response) => {
    triggerLoader(false);
    return response;
  },
  async (error) => {
    triggerLoader(false);
    if (error.response && error.response.status === 401) {
      try {
        await tryToReLogin();
        const accessToken = localStorage.getItem("accessToken")
        const { config: oldRequest } = error;
        return await axios.request({
          ...oldRequest,
          headers: { Authorization: `Bearer ${accessToken}`, "Content-Type": "application/json" },
        });
      } catch (e) {
        await dispatch(logout());
      }
    }

    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

axiosInstance.interceptors.request.use(
  (response) => {
    triggerLoader(true);
    return response;
  },
  (error) => {
    triggerLoader(false);
    return Promise.reject(error);
  }
);

export default axiosInstance;
