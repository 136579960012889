// routes
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import LoadingScreen from './components/LoadingScreen';
import ErrorBoundary from './utils/ErrorBoundary';
import useLocales from './hooks/useLocales';
import 'moment/locale/kk'
import 'moment/locale/en-gb'
import 'moment/locale/ru'
import WelcomeModal from './components/WelcomeModal';

// ----------------------------------------------------------------------

export default function App() {
  const { currentLang } = useLocales();

  useEffect(() => {
    moment.locale(currentLang.value)
  }, [currentLang])

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={currentLang.dateLocale}>
        <ThemeColorPresets>
          <ThemeLocalization>
            <ErrorBoundary>
              <RtlLayout>
                <NotistackProvider>
                  <MotionLazyContainer>
                    <ProgressBarStyle />
                    <ChartStyle />
                    <Settings />
                    <ScrollToTop />
                    <Router />
                    <LoadingScreen />
                    <WelcomeModal/>
                  </MotionLazyContainer>
                </NotistackProvider>
              </RtlLayout>
            </ErrorBoundary>
          </ThemeLocalization>
        </ThemeColorPresets>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
