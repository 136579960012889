import { format, getTime, formatDistanceToNow } from 'date-fns';
import { ru } from 'date-fns/locale';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd.MM.yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd.MM.yyyy hh:mm');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
    locale: ru,
  });
}

export const getStartOfTheDay = (moment) => (moment ? Date.parse(moment.hours(0).minutes(0).seconds(0)) : null);
export const getEndOfTheDay = (moment) => (moment ? Date.parse(moment.hours(23).minutes(59).seconds(59)) : null);
