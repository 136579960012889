import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import { PAGE_NAMES } from '../constants/constants';
import AccessGuard from '../guards/AccessGuard';

// ----------------------------------------------------------------------

const Loadable = (Component: any) => ({ claims, ...props }: any) => {
    const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <AccessGuard claims={claims}>
        <Component {...props} />
      </AccessGuard>
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'stats', element: <Statistics claims={[PAGE_NAMES.REPORT_LIST]} /> },
        { path: 'report', element: <GeneralStatistic claims={[PAGE_NAMES.REPORT_LIST]} /> },
        { path: 'print', element: <PrintPage /> },
        {
          path: 'sales',
          children: [
            { element: <Navigate to="/sales/list" replace />, index: true },
            { path: 'list', element: <SalesList claims={[PAGE_NAMES.SALES_LIST]} /> },
            { path: 'create', element: <SalesCreate claims={[PAGE_NAMES.SALES_CREATE]} /> },
            { path: 'edit/:id', element: <SalesEdit claims={[PAGE_NAMES.SALES_VIEW]} /> },
          ],
        },
        {
          path: 'exchange',
          children: [
            { element: <Navigate to="/exchange/list" replace />, index: true },
            { path: 'list', element: <ExchangeView claims={[PAGE_NAMES.EXCHANGE_LIST]} /> },
            { path: 'create', element: <ExchangeCreate claims={[PAGE_NAMES.EXCHANGE_CREATE]} /> },
            { path: 'edit/:id', element: <ExchangeEdit claims={[PAGE_NAMES.EXCHANGE_VIEW]} /> },
          ],
        },
        /*{
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit-create', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
            { path: 'invoice', element: <EcommerceInvoice /> },
          ],
        },*/
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            // { path: 'profile', element: <UserProfile /> },
            // { path: 'cards', element: <UserCards /> },
            // { path: 'payment', element: <PaymentList /> },
            {
              path: 'customer-connection',
              children: [
                { element: <Navigate to="/dashboard/user/customer-connection/list" />, index: true },
                { path: 'list', element: <CustomerConnection /> },
                { path: ':id', element: <CustomerConnectionDetails /> },
              ]
            },
            { path: 'list', element: <UserList claims={[PAGE_NAMES.USER_LIST]} /> },
            { path: 'new', element: <UserCreate claims={[PAGE_NAMES.USER_CREATE]} /> },
            { path: ':id/edit', element: <UserCreate claims={[PAGE_NAMES.USER_EDIT]} /> },
            { path: 'account', element: <UserAccount /> },
            { path: 'partner/list', element: <PartnerList /> },
            { path: 'package/list', element: <PackageList /> },
            {
              path: 'roles',
              children: [
                { path: 'list', element: <RolesList claims={[PAGE_NAMES.ROLE_LIST]} /> },
                { path: 'new', element: <RoleCreateEdit claims={[PAGE_NAMES.ROLE_CREATE]} /> },
                { path: ':id', element: <RoleCreateEdit claims={[PAGE_NAMES.ROLE_EDIT]} /> },
              ],
            },
          ],
        },
        {
          path: 'shop-branch',
          children: [
            { element: <Navigate to="/dashboard/shop-branch/list" replace />, index: true },
            { path: 'list', element: <ShopBranchList claims={[PAGE_NAMES.SHOP_BRANCH_LIST]} /> },
            { path: 'new', element: <ShopBranchCreate claims={[PAGE_NAMES.SHOP_BRANCH_CREATE]} /> },
            { path: ':id', element: <ShopBranchCreate claims={[PAGE_NAMES.SHOP_BRANCH_EDIT]} /> },
          ],
        },
        {
          path: 'shops',
          children: [
            { element: <Navigate to="/dashboard/shops/list" replace />, index: true },
            { path: 'list', element: <ShopList claims={[PAGE_NAMES.SHOP_LIST]} /> },
            { path: 'new', element: <ShopCreate claims={[PAGE_NAMES.SHOP_CREATE]} /> },
            { path: ':id', element: <ShopCreate claims={[PAGE_NAMES.SHOP_EDIT]} /> },
          ],
        },
        {
          path: 'country',
          children: [
            { element: <Navigate to="/dashboard/country/list" replace />, index: true },
            { path: 'list', element: <CountryList claims={[PAGE_NAMES.COUNTRY_LIST]} /> },
            { path: 'new', element: <CountryCreate claims={[PAGE_NAMES.COUNTRY_CREATE]} /> },
            { path: ':id', element: <CountryCreate claims={[PAGE_NAMES.COUNTRY_EDIT]} /> },
          ],
        },
        {
          path: 'city',
          children: [
            { element: <Navigate to="/dashboard/city/list" replace />, index: true },
            { path: 'list', element: <CityList claims={[PAGE_NAMES.CITY_LIST]} /> },
            { path: 'new', element: <CityCreate claims={[PAGE_NAMES.CITY_CREATE]} /> },
            { path: ':id', element: <CityCreate claims={[PAGE_NAMES.CITY_EDIT]} /> },
          ],
        },
        /*{
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new-post', element: <BlogNewPost /> },
          ],
        },*/
        {
          path: 'devices',
          children: [
            { element: <Navigate to="/dashboard/devices/brand/list" replace />, index: true },
            { path: 'list', element: <DeviceList claims={[PAGE_NAMES.DEVICE_LIST]} /> },
            { path: 'new', element: <DeviceCreate claims={[PAGE_NAMES.DEVICE_CREATE]} /> },
            { path: ':id', element: <DeviceCreate claims={[PAGE_NAMES.DEVICE_EDIT]} /> },
            { path: 'brand/list', element: <DeviceBrandList claims={[PAGE_NAMES.DEVICE_BRAND_LIST]} /> },
            { path: 'brand/new', element: <NewDeviceBrand claims={[PAGE_NAMES.DEVICE_BRAND_CREATE]} /> },
            { path: 'brand/:id', element: <NewDeviceBrand claims={[PAGE_NAMES.DEVICE_BRAND_EDIT]} /> },
          ],
        },
        {
          path: 'certificate',
          children: [
            { element: <Navigate to="/certificate/series/list" replace />, index: true },
            {
              path: 'series',
              children: [
                { path: 'list', element: <CertificateSeriesList claims={[PAGE_NAMES.CERTIFICATE_SERIES_LIST]} /> },
                { path: 'new', element: <CertificateSeriesCreate claims={[PAGE_NAMES.CERTIFICATE_SERIES_CREATE]} /> },
                { path: ':id', element: <CertificateSeriesCreate claims={[PAGE_NAMES.CERTIFICATE_SERIES_EDIT]} /> },
              ],
            },
          ],
        },
        {
          path: 'bonus',
          children: [
            { element: <Navigate to="/dashboard/bonus/list" replace />, index: true },
            { path: 'list', element: <BonusList claims={[PAGE_NAMES.BONUSES_LIST]} /> },
            { path: 'manual_management', element: <ManualBonusManagement claims={[PAGE_NAMES.BONUSES_EDIT]} /> },
            {
              path: 'promo_code',
              children: [
                { element: <Navigate to="/dashboard/bonus/promo_code/list" replace />, index: true },
                { path: 'list', element: <PromoCodeList claims={[PAGE_NAMES.PROMO_CODE_LIST]} /> },
                { path: 'new', element: <PromoCodeCreate claims={[PAGE_NAMES.PROMO_CODE_CREATE]} /> },
                { path: ':id', element: <PromoCodeCreate claims={[PAGE_NAMES.PROMO_CODE_EDIT]} /> },
              ],
            },
            {
              path: 'cashback',
              children: [
                { element: <Navigate to="/dashboard/bonus/cashback/list" replace />, index: true },
                { path: 'list', element: <CashbackList claims={[PAGE_NAMES.CASHBACK_LIST]} /> },
                { path: 'new', element: <CashbackCreate claims={[PAGE_NAMES.CASHBACK_CREATE]} /> },
                { path: ':id', element: <CashbackCreate claims={[PAGE_NAMES.CASHBACK_EDIT]} /> },
              ],
            },
            {
              path: 'promotion',
              children: [
                { element: <Navigate to="/dashboard/bonus/promotion/list" replace />, index: true },
                { path: 'list', element: <PromotionList claims={[PAGE_NAMES.PROMOTION_LIST]} /> },
                { path: 'new', element: <PromotionCreate claims={[PAGE_NAMES.PROMOTION_CREATE]} /> },
                { path: ':id', element: <PromotionCreate claims={[PAGE_NAMES.PROMOTION_EDIT]} /> },
              ],
            },
          ],
        },
        {
          path: 'other',
          children: [
            { element: <Navigate to="/other/percentage-price/list" replace />, index: true },
            {
              path: 'percentage-price',
              children: [
                { path: 'list', element: <PercentagePriceList claims={[PAGE_NAMES.PRICE_PERCENT_LIST]} /> },
                { path: 'new', element: <PercentagePriceCreate claims={[PAGE_NAMES.PRICE_PERCENT_CREATE]} /> },
                { path: ':id', element: <PercentagePriceCreate claims={[PAGE_NAMES.PRICE_PERCENT_EDIT]} /> },
              ],
            },
            {
              path: 'category',
              children: [
                { path: 'list', element: <CategoryList claims={[PAGE_NAMES.CATEGORY_LIST]} /> },
                { path: 'new', element: <CategoryCreate claims={[PAGE_NAMES.CATEGORY_CREATE]} /> },
                { path: ':id', element: <CategoryCreate claims={[PAGE_NAMES.CATEGORY_EDIT]} /> },
              ],
            },
            {
              path: 'category-type',
              children: [
                { path: 'list', element: <CategoryTypeList claims={[PAGE_NAMES.CATEGORY_LIST]} /> },
                { path: 'new', element: <CategoryTypeCreate claims={[PAGE_NAMES.CATEGORY_CREATE]} /> },
                { path: ':id', element: <CategoryTypeCreate claims={[PAGE_NAMES.CATEGORY_EDIT]} /> },
              ],
            },
            {
              path: 'percentage-compensation',
              children: [
                { path: 'list', element: <PercentageCompensationList claims={[PAGE_NAMES.COMPENSATION_PERCENT_LIST]} /> },
                { path: 'new', element: <PercentageCompensationCreate claims={[PAGE_NAMES.COMPENSATION_PERCENT_CREATE]} /> },
                { path: ':id', element: <PercentageCompensationCreate claims={[PAGE_NAMES.COMPENSATION_PERCENT_EDIT]} /> },
              ],
            },
          ],
        },
        /*{
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },*/
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <Navigate to="/dashboard" replace />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralStatistic = Loadable(lazy(() => import('../pages/dashboard/GeneralStatistic')));
const Statistics = Loadable(lazy(() => import('../pages/dashboard/Statistics')));
const PrintPage = Loadable(lazy(() => import('../sections/@dashboard/print')));
const ShopBranchList = Loadable(lazy(() => import('../sections/@dashboard/shop-branch/list')));
const ShopBranchCreate = Loadable(lazy(() => import('../sections/@dashboard/shop-branch/create')));
const ShopList = Loadable(lazy(() => import('../sections/@dashboard/shops/list')));
const ShopCreate = Loadable(lazy(() => import('../sections/@dashboard/shops/create')));
const CountryList = Loadable(lazy(() => import('../sections/@dashboard/country/list')));
const CountryCreate = Loadable(lazy(() => import('../sections/@dashboard/country/create')));
const CityList = Loadable(lazy(() => import('../sections/@dashboard/city/list')));
const CityCreate = Loadable(lazy(() => import('../sections/@dashboard/city/create')));
const CertificateSeriesList = Loadable(lazy(() => import('../sections/@dashboard/series/list')));
const CertificateSeriesCreate = Loadable(lazy(() => import('../sections/@dashboard/series/create')));
const PercentagePriceList = Loadable(lazy(() => import('../sections/@dashboard/percentage-price/list')));
const PercentagePriceCreate = Loadable(lazy(() => import('../sections/@dashboard/percentage-price/create')));
const BonusList = Loadable(lazy(() => import('../sections/@dashboard/bonus/list')));
const ManualBonusManagement = Loadable(lazy(() => import('../sections/@dashboard/bonus/management')));
const PromoCodeList = Loadable(lazy(() => import('../sections/@dashboard/promo-code/list')));
const PromoCodeCreate = Loadable(lazy(() => import('../sections/@dashboard/promo-code/create')));
const CashbackList = Loadable(lazy(() => import('../sections/@dashboard/cashback/list')));
const CashbackCreate = Loadable(lazy(() => import('../sections/@dashboard/cashback/create')));
const PromotionList = Loadable(lazy(() => import('../sections/@dashboard/promotion/list')));
const PromotionCreate = Loadable(lazy(() => import('../sections/@dashboard/promotion/create')));
const CategoryList = Loadable(lazy(() => import('../sections/@dashboard/category/list')));
const CategoryCreate = Loadable(lazy(() => import('../sections/@dashboard/category/create')));
const CategoryTypeList = Loadable(lazy(() => import('../sections/@dashboard/category-type/list')));
const CategoryTypeCreate = Loadable(lazy(() => import('../sections/@dashboard/category-type/create')));
const PercentageCompensationList = Loadable(lazy(() => import('../sections/@dashboard/percentage-compensation-service/list')));
const PercentageCompensationCreate = Loadable(lazy(() => import('../sections/@dashboard/percentage-compensation-service/create')));
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../sections/@dashboard/user/list')));
const RolesList = Loadable(lazy(() => import('../sections/@dashboard/user/roles/list')));
const PartnerList = Loadable(lazy(() => import('../sections/@dashboard/partner/list')));
const PackageList = Loadable(lazy(() => import('../sections/@dashboard/package/list')));
const RoleCreateEdit = Loadable(lazy(() => import('../sections/@dashboard/user/roles/edit-create')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../sections/@dashboard/user/edit-create')));
const PaymentList = Loadable(lazy(() => import('../sections/@dashboard/payment/list')));
const CustomerConnection = Loadable(lazy(() => import('../sections/@dashboard/customer-connection/list')));
const CustomerConnectionDetails = Loadable(lazy(() => import('../sections/@dashboard/customer-connection/details')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
const DeviceList = Loadable(lazy(() => import('../sections/@dashboard/device/list')));
const DeviceCreate = Loadable(lazy(() => import('../sections/@dashboard/device/create')));
const DeviceBrandList = Loadable(lazy(() => import('../sections/@dashboard/device-brand/list')));
const NewDeviceBrand = Loadable(lazy(() => import('../sections/@dashboard/device-brand/create-edit')));
const SalesList = Loadable(lazy(() => import('../sections/@dashboard/sales/list')));
const SalesCreate = Loadable(lazy(() => import('../sections/@dashboard/sales/create')));
const SalesEdit = Loadable(lazy(() => import('../sections/@dashboard/sales/create')));
const ExchangeView = Loadable(lazy(() => import('../sections/@dashboard/exchange/list')));
const ExchangeCreate = Loadable(lazy(() => import('../sections/@dashboard/exchange/create')));
const ExchangeEdit = Loadable(lazy(() => import('../sections/@dashboard/exchange/create')));
// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
